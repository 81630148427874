import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { AppRouter } from './AppRouter'
import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Création du client Query avec les options par défaut
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
})

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider>
    </React.StrictMode>
  )
} 