import React, { Suspense, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { LoadingSpinner } from '../components/ui/loading-spinner';
import { Sidebar } from '../components/Sidebar';
import { Header } from '../components/Header';
import { Menu, X } from 'lucide-react';
import { ErrorBoundary } from 'react-error-boundary';

// Composant de fallback pour le chargement
const LoadingFallback = () => (
  <div className="flex items-center justify-center h-[calc(100vh-200px)]">
    <LoadingSpinner 
      size="lg"
      text="Chargement en cours..."
    />
  </div>
);

// Composant de fallback pour les erreurs
const ErrorFallback = ({ error, resetErrorBoundary }: { 
  error: Error; 
  resetErrorBoundary: () => void;
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] p-6">
      <h2 className="text-xl font-semibold text-red-600 mb-4">
        Une erreur est survenue
      </h2>
      <p className="text-muted-foreground mb-4">
        {error.message}
      </p>
      <button
        onClick={resetErrorBoundary}
        className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90"
      >
        Réessayer
      </button>
    </div>
  );
};

export const DashboardLayout = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Bouton menu mobile */}
      <button
        onClick={toggleMobileMenu}
        className="md:hidden fixed top-4 left-4 z-50 p-2 bg-primary text-white rounded-lg"
        aria-label={isMobileMenuOpen ? "Fermer le menu" : "Ouvrir le menu"}
      >
        <motion.div
          initial={false}
          animate={{ rotate: isMobileMenuOpen ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </motion.div>
      </button>

      <div className="flex">
        {/* Sidebar - Mobile */}
        <motion.div
          className="fixed inset-y-0 left-0 z-40 md:hidden"
          initial={false}
          animate={{
            x: isMobileMenuOpen ? 0 : "-100%"
          }}
          transition={{ type: "spring", damping: 20 }}
        >
          <div className="h-full">
            <Sidebar onCloseMobileMenu={() => setIsMobileMenuOpen(false)} />
          </div>
        </motion.div>

        {/* Sidebar - Desktop */}
        <div className="hidden md:block fixed inset-y-0 left-0 w-64">
          <Sidebar onCloseMobileMenu={() => null} />
        </div>

        {/* Contenu principal */}
        <div className="flex-1 md:ml-64">
          {/* Header fixe */}
          <div className="sticky top-0 z-30">
            <Header />
          </div>

          {/* Zone de défilement */}
          <main className="flex-1 p-6 overflow-auto">
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => window.location.reload()}
            >
              <AnimatePresence mode="wait">
                <motion.div
                  key={location.pathname}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                >
                  <Suspense fallback={<LoadingFallback />}>
                    <Outlet />
                  </Suspense>
                </motion.div>
              </AnimatePresence>
            </ErrorBoundary>
          </main>
        </div>
      </div>

      {/* Overlay pour le menu mobile */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
}; 