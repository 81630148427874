import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AUTH_CONFIG, isTokenValid } from "../config/auth.config";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
  checkAuth: () => boolean;
  token: string | null;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const token = localStorage.getItem(AUTH_CONFIG.TOKEN_KEY);
    return !!token && isTokenValid(token);
  });
  
  const [token, setToken] = useState<string | null>(() => {
    return localStorage.getItem(AUTH_CONFIG.TOKEN_KEY);
  });

  const checkAuth = useCallback(() => {
    const storedToken = localStorage.getItem(AUTH_CONFIG.TOKEN_KEY);
    if (!storedToken || !isTokenValid(storedToken)) {
      handleLogout();
      return false;
    }
    return true;
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(AUTH_CONFIG.TOKEN_KEY);
    localStorage.removeItem(AUTH_CONFIG.CREDENTIALS_KEY);
    setIsAuthenticated(false);
    setToken(null);
    
    // Ne pas rediriger vers login si on y est déjà
    if (location.pathname !== AUTH_CONFIG.AUTH_ROUTES.LOGIN) {
      navigate(AUTH_CONFIG.AUTH_ROUTES.LOGIN);
      toast.error("Session terminée");
    }
  }, [navigate, location]);

  const login = useCallback((newToken: string) => {
    localStorage.setItem(AUTH_CONFIG.TOKEN_KEY, newToken);
    setToken(newToken);
    setIsAuthenticated(true);
  }, []);

  // Vérifier périodiquement la validité du token
  useEffect(() => {
    if (!isAuthenticated) return;

    const interval = setInterval(checkAuth, AUTH_CONFIG.REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [isAuthenticated, checkAuth]);

  // Vérifier le token au montage
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const contextValue: AuthContextType = {
    isAuthenticated,
    login,
    logout: handleLogout,
    checkAuth,
    token
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };