import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import { DashboardLayout } from "./layouts/DashboardLayout";
import { routes } from "./routes/routes";
import { LoadingSpinner } from "./components/ui/loading-spinner";
import { Toaster } from "react-hot-toast";

// Lazy loading des pages
const Login = React.lazy(() => import("./pages/Login").then(module => ({ default: module.Login })));
const NotFound = React.lazy(() => import("./pages/NotFound").then(module => ({ default: module.NotFound })));
const UserDetails = React.lazy(() => import("./pages/UserDetails").then(module => ({ default: module.UserDetails })));

// Composant de chargement pour le lazy loading
const PageLoader = () => (
  <div className="flex items-center justify-center min-h-screen">
    <LoadingSpinner size="lg" text="Chargement de la page..." />
  </div>
);

// Composant pour les routes authentifiées
const AuthenticatedRoutes = () => (
  <Routes>
    {/* Route publique */}
    <Route
      path="/login"
      element={
        <ProtectedRoute requiresAuth={false}>
          <Login />
        </ProtectedRoute>
      }
    />

    {/* Routes protégées */}
    <Route
      path="/"
      element={
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      }
    >
      {/* Routes dynamiques depuis routes.ts */}
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path === "/" ? "" : route.path.slice(1)}
          element={
            <Suspense fallback={<PageLoader />}>
              <route.component />
            </Suspense>
          }
        />
      ))}
      
      {/* Route spéciale pour les détails CV */}
      <Route 
        path="cv-details/:cvModel" 
        element={
          <Suspense fallback={<PageLoader />}>
            <UserDetails />
          </Suspense>
        } 
      />
    </Route>

    {/* Route 404 */}
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <AuthProvider>
          <AuthenticatedRoutes />
        </AuthProvider>
        <Toaster position="top-right" />
      </Suspense>
    </BrowserRouter>
  );
}