export const AUTH_CONFIG = {
  TOKEN_KEY: "ariah_token",
  CREDENTIALS_KEY: "ariah_credentials",
  REMEMBER_ME_KEY: 'rememberMe',
  TOKEN_EXPIRY: 24 * 60 * 60 * 1000, // 24 heures en millisecondes
  REFRESH_INTERVAL: 5 * 60 * 1000, // 5 minutes
  AUTH_ROUTES: {
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    CV_DETAILS: "/cv-details",
    DEFAULT_REDIRECT: '/',
    UNAUTHORIZED: '/unauthorized'
  }
} as const;

export const isTokenValid = (token: string): boolean => {
  try {
    // Vérifier si le token est une chaîne non vide
    if (!token || typeof token !== "string") {
      return false;
    }

    // Vérifier si le token est au format JWT
    const parts = token.split(".");
    if (parts.length !== 3) {
      return false;
    }

    // Décoder le payload
    const payload = JSON.parse(atob(parts[1]));

    // Vérifier l'expiration
    if (!payload.exp) {
      return false;
    }

    const expirationDate = new Date(payload.exp * 1000);
    return expirationDate > new Date();
  } catch (error) {
    console.error("Erreur lors de la validation du token:", error);
    return false;
  }
};

export const parseToken = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch {
    return null;
  }
}; 