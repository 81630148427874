import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { LoadingSpinner } from '../ui/loading-spinner';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiresAuth?: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requiresAuth = true,
}) => {
  const { isAuthenticated, checkAuth } = useAuth();
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const verifyAuth = async () => {
      await checkAuth();
      setIsChecking(false);
    };
    verifyAuth();
  }, [checkAuth]);

  if (isChecking) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner size="lg" text="Vérification de l'authentification..." />
      </div>
    );
  }

  if (!isAuthenticated && requiresAuth) {
    // Stocker l'URL actuelle pour la redirection post-login
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  // Rediriger vers le dashboard si déjà connecté et sur une page publique
  if (isAuthenticated && !requiresAuth && location.pathname === '/login') {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}; 